// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
import "@hotwired/turbo-rails"
require("@rails/activestorage").start()
import '../stylesheets/application.scss';
import flatpickr from "flatpickr";
import 'select2';
import 'select2/dist/css/select2.css'; 
import $ from 'jquery';

const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

window.jQuery = window.$ = require('jquery')

$(document).on('turbo:load', function() {
  $('#order_country').select2();

  $('.open-new-user', ).on('click', function(){
    $('#new-user-modal').toggle();
  });

  $('.open-new-licence').on('click', function(){
    const userId = $(this).data('user-id');
    $('.c-modal--licence-modal').hide();
    $(`#new-license-${userId}`).toggle();
  });

  $('.open-active-licence').on('click', function(){
    let licence = $(this).data('id');
    $(`#activate-license-${licence}`).toggle();
  });

  $('.c-product-box__radio').on('click', function() {
    $('#order-step1-submit').trigger('click');
  });  
});